import { useContext, useEffect, useState } from 'react';
import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  TooltipProps,
  useMediaQuery,
  useTheme,
  Theme
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import {
  sidebarMenuAdmin,
  sidebarMenuBusiness,
  sidebarMenuSubBusiness,
  sidebarMenuTeacher
} from 'src/SidebarMenu/sidebar.service';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { Tooltip, tooltipClasses } from '@mui/material';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: 12px 0px;
    margin-bottom: -5px;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: ${500};
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
      
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 3px;
        margin-top: -5px;
        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)}; 
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background: ${alpha(theme.colors.alpha.purple[100], 1)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(2, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0px;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const CustomTooltip = styled(
  ({ className, ...props }: TooltipProps & { className?: string }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#380563',
    color: 'white',
    fontSize: 14
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#380563'
  }
}));

function SidebarMenu({ collapsed }) {
  const { closeSidebar } = useContext(SidebarContext);
  const [userType, SetUserType] = useState(null);
  const [userPermissions, setUserPermissions] = useState([]);
  const reduxPermissionData = useSelector(
    (state: RootState) => state.auth.permissions
  );
  const reduxUserData = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    if (reduxPermissionData && reduxUserData) {
      SetUserType(reduxUserData.userType);
      setUserPermissions(reduxPermissionData);
    }
  }, [reduxUserData, reduxPermissionData]);

  const renderMenuItems = (menuData) => {
    return menuData.map((menu, index) => {
      const filteredSubMenu = menu.subMenu.filter(
        (item) => !item.permission || userPermissions.includes(item.permission)
      );

      if (filteredSubMenu.length === 0) {
        return null;
      }

      return (
        <List
          key={index}
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              {isLg ? <> {menu.caption}</> : !collapsed && <> {menu.caption}</>}
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              {filteredSubMenu.map((item, subIndex) => (
                <ListItem component="div" key={subIndex} sx={{ mb: 0.5 }}>
                  <CustomTooltip
                    title={isLg ? '' : `${collapsed ? item.caption : ''}`}
                    placement="right"
                    arrow
                  >
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to={item.navigate}
                      startIcon={
                        <img
                          src={item.image}
                          style={{
                            color: '#CECDCE',
                            marginLeft: collapsed ? '13px' : '0px',
                            height: '20px'
                          }}
                        />
                      }
                    >
                      {isLg ? (
                        <>{item.caption}</>
                      ) : (
                        !collapsed && <>{item.caption}</>
                      )}
                    </Button>
                  </CustomTooltip>
                </ListItem>
              ))}
            </List>
          </SubMenuWrapper>
        </List>
      );
    });
  };

  let menuData = [];
  switch (userType) {
    case 'ADMIN':
      menuData = sidebarMenuAdmin;
      break;
    case 'BUSINESS':
      menuData = sidebarMenuBusiness;
      break;
    case 'TEACHER':
      menuData = sidebarMenuTeacher;
      break;
    case 'SUB_BUSINESS':
      menuData = sidebarMenuSubBusiness;
      break;
    default:
      menuData = [];
      break;
  }
  const theme = useTheme<Theme>();
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <MenuWrapper>
      {userType === 'SUPER-ADMIN' ? (
        <>
          <List component="div">
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <CustomTooltip
                    title={isLg ? '' : `${collapsed ? 'Dashboard' : ''}`}
                    placement="right"
                    arrow
                  >
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/dashboard"
                      startIcon={
                        <img
                          src="/static/images/sidebar/Dashboard.svg"
                          style={{
                            color: '#CECDCE',
                            marginLeft: collapsed ? '13px' : '0px',
                            height: '20px'
                          }}
                        />
                      }
                      sx={{ fontWeight: '500' }}
                    >
                      {isLg ? 'Dashboard' : !collapsed && 'Dashboard'}
                    </Button>
                  </CustomTooltip>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                {isLg ? 'Assessment Module' : !collapsed && 'Assessment Module'}
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div" sx={{ mb: 0.5 }}>
                  <CustomTooltip
                    title={
                      isLg ? '' : `${collapsed ? 'Manage Assessments' : ''}`
                    }
                    placement="right"
                    arrow
                  >
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/assessment/list"
                      startIcon={
                        <img
                          src="/static/images/sidebar/Assessment.svg"
                          style={{
                            color: '#CECDCE',
                            marginLeft: collapsed ? '13px' : '0px',
                            height: '20px'
                          }}
                        />
                      }
                      sx={{ fontWeight: '500' }}
                    >
                      {isLg
                        ? 'Manage Assessments'
                        : !collapsed && 'Manage Assessments'}
                    </Button>
                  </CustomTooltip>
                </ListItem>
                <ListItem component="div" sx={{ mb: 0.5 }}>
                  <CustomTooltip
                    title={
                      isLg ? '' : `${collapsed ? 'Manage Questions ' : ''}`
                    }
                    placement="right"
                    arrow
                  >
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/question"
                      startIcon={
                        <img
                          src="/static/images/sidebar/Question_Pool.svg"
                          style={{
                            color: '#CECDCE',
                            marginLeft: collapsed ? '13px' : '0px',
                            height: '20px'
                          }}
                        />
                      }
                      sx={{ fontWeight: '500' }}
                    >
                      {isLg
                        ? 'Manage Questions '
                        : !collapsed && 'Manage Questions '}
                    </Button>
                  </CustomTooltip>
                </ListItem>
                <ListItem component="div">
                  <CustomTooltip
                    title={
                      isLg ? '' : `${collapsed ? 'Add to Question Pool' : ''}`
                    }
                    placement="right"
                    arrow
                  >
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/question-group"
                      startIcon={
                        <img
                          src="/static/images/sidebar/Question_Pool.svg"
                          style={{
                            color: '#CECDCE',
                            marginLeft: collapsed ? '13px' : '0px',
                            height: '20px'
                          }}
                        />
                      }
                    >
                      {isLg
                        ? 'Add to Question Group'
                        : !collapsed && 'Add to Question Group'}
                    </Button>
                  </CustomTooltip>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                {isLg ? 'Organization' : !collapsed && 'Organization'}
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <CustomTooltip
                    title={
                      isLg ? '' : `${collapsed ? 'Manage Organizations' : ''}`
                    }
                    placement="right"
                    arrow
                  >
                    <Button
                      id="1"
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/business"
                      startIcon={
                        <img
                          src="/static/images/sidebar/Business.svg"
                          style={{
                            color: '#CECDCE',
                            marginLeft: collapsed ? '13px' : '0px',
                            height: '20px'
                          }}
                        />
                      }
                      sx={{ fontWeight: '500' }}
                    >
                      {isLg
                        ? 'Manage Organizations'
                        : !collapsed && 'Manage Organizations'}
                    </Button>
                  </CustomTooltip>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                {isLg ? 'Users' : !collapsed && 'Users'}
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div" sx={{ mb: 0.5 }}>
                  <CustomTooltip
                    title={isLg ? '' : `${collapsed ? 'Manage Teachers' : ''}`}
                    placement="right"
                    arrow
                  >
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/teacher"
                      startIcon={
                        <img
                          src="/static/images/sidebar/Teachers.svg"
                          style={{
                            color: '#CECDCE',
                            marginLeft: collapsed ? '13px' : '0px',
                            height: '20px'
                          }}
                        />
                      }
                      sx={{ fontWeight: '500' }}
                    >
                      {isLg
                        ? 'Manage Teachers'
                        : !collapsed && 'Manage Teachers'}
                    </Button>
                  </CustomTooltip>
                </ListItem>
                <ListItem component="div" sx={{ mb: 0.5 }}>
                  <CustomTooltip
                    title={isLg ? '' : `${collapsed ? 'Administration' : ''}`}
                    placement="right"
                    arrow
                  >
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/admin"
                      startIcon={
                        <img
                          src="/static/images/sidebar/Admin.svg"
                          style={{
                            color: '#CECDCE',
                            marginLeft: collapsed ? '13px' : '0px',
                            height: '20px'
                          }}
                        />
                      }
                      sx={{ fontWeight: '500' }}
                    >
                      {isLg ? 'Administration' : !collapsed && 'Administration'}
                    </Button>
                  </CustomTooltip>
                </ListItem>
                <ListItem component="div">
                  <CustomTooltip
                    title={isLg ? '' : `${collapsed ? 'Manage Batches' : ''}`}
                    placement="right"
                    arrow
                  >
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/batch"
                      startIcon={
                        <img
                          src="/static/images/sidebar/Admin.svg"
                          style={{
                            color: '#CECDCE',
                            marginLeft: collapsed ? '13px' : '0px',
                            height: '20px'
                          }}
                        />
                      }
                      sx={{ fontWeight: '500' }}
                    >
                      {isLg ? 'Manage Batches' : !collapsed && 'Manage Batches'}
                    </Button>
                  </CustomTooltip>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                {isLg ? 'Report' : !collapsed && 'Report'}
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <CustomTooltip
                    title={isLg ? '' : `${collapsed ? 'Report' : ''}`}
                    placement="right"
                    arrow
                  >
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/report"
                      startIcon={
                        <img
                          src="/static/images/sidebar/Report.svg"
                          style={{
                            color: '#CECDCE',
                            marginLeft: collapsed ? '13px' : '0px',
                            height: '20px'
                          }}
                        />
                      }
                      sx={{ fontWeight: '500' }}
                    >
                      {isLg ? 'Report' : !collapsed && 'Report'}
                    </Button>
                  </CustomTooltip>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                {isLg ? 'Tag Management' : !collapsed && 'Tag Management'}
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div" sx={{ mb: 0.5 }}>
                  <CustomTooltip
                    title={isLg ? '' : `${collapsed ? 'Grade' : ''}`}
                    placement="right"
                    arrow
                  >
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/grade"
                      startIcon={
                        <img
                          src="/static/images/sidebar/Grade.svg"
                          style={{
                            color: '#CECDCE',
                            marginLeft: collapsed ? '13px' : '0px',
                            height: '20px'
                          }}
                        />
                      }
                      sx={{ fontWeight: '500' }}
                    >
                      {isLg ? 'Grade' : !collapsed && 'Grade'}
                    </Button>
                  </CustomTooltip>
                </ListItem>
                <ListItem component="div" sx={{ mb: 0.5 }}>
                  <CustomTooltip
                    title={isLg ? '' : `${collapsed ? 'Subject' : ''}`}
                    placement="right"
                    arrow
                  >
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/subject"
                      startIcon={
                        <img
                          src="/static/images/sidebar/Subject.svg"
                          style={{
                            color: '#CECDCE',
                            marginLeft: collapsed ? '13px' : '0px',
                            height: '20px'
                          }}
                        />
                      }
                      sx={{ fontWeight: '500' }}
                    >
                      {isLg ? 'Subject' : !collapsed && 'Subject'}
                    </Button>
                  </CustomTooltip>
                </ListItem>
                <ListItem component="div" sx={{ mb: 0.5 }}>
                  <CustomTooltip
                    title={isLg ? '' : `${collapsed ? 'Competencies' : ''}`}
                    placement="right"
                    arrow
                  >
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/subparameter"
                      startIcon={
                        <img
                          src="/static/images/sidebar/Grade.svg"
                          style={{
                            color: '#CECDCE',
                            marginLeft: collapsed ? '13px' : '0px',
                            height: '20px'
                          }}
                        />
                      }
                      sx={{ fontWeight: '500' }}
                    >
                      {isLg ? 'Competencies' : !collapsed && 'Competencies'}
                    </Button>
                  </CustomTooltip>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                {isLg ? 'Settings' : !collapsed && 'Settings'}
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div" sx={{ mb: 0.5 }}>
                  <CustomTooltip
                    title={
                      isLg
                        ? ''
                        : `${collapsed ? 'Manage Roles and Privileges' : ''}`
                    }
                    placement="right"
                    arrow
                  >
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/role"
                      startIcon={
                        <img
                          src="/static/images/sidebar/Add_user.svg"
                          style={{
                            color: '#CECDCE',
                            marginLeft: collapsed ? '13px' : '0px',
                            height: '20px'
                          }}
                        />
                      }
                      sx={{ fontWeight: '500' }}
                    >
                      {isLg
                        ? 'Manage Roles and Privileges'
                        : !collapsed && 'Manage Roles and Privileges'}
                    </Button>
                  </CustomTooltip>
                </ListItem>
                <ListItem component="div" sx={{ mb: 0.5 }}>
                  <CustomTooltip
                    title={
                      isLg ? '' : `${collapsed ? 'View Activity log' : ''}`
                    }
                    placement="right"
                    arrow
                  >
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/activityLog"
                      startIcon={
                        <img
                          src="/static/images/sidebar/Activity.svg"
                          style={{
                            color: '#CECDCE',
                            marginLeft: collapsed ? '13px' : '0px',
                            height: '20px'
                          }}
                        />
                      }
                      sx={{ fontWeight: '500' }}
                    >
                      {isLg
                        ? 'View Activity log'
                        : !collapsed && 'View Activity log'}
                    </Button>
                  </CustomTooltip>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
        </>
      ) : (
        <>
          <List component="div">
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <CustomTooltip
                    title={isLg ? '' : `${collapsed ? 'Dashboard' : ''}`}
                    placement="right"
                    arrow
                  >
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/dashboard"
                      startIcon={
                        <img
                          src="/static/images/sidebar/Dashboard.svg"
                          style={{
                            color: '#CECDCE',
                            marginLeft: collapsed ? '13px' : '0px',
                            height: '20px'
                          }}
                        />
                      }
                      sx={{ fontWeight: '500' }}
                    >
                      {isLg ? 'Dashboard' : !collapsed && 'Dashboard'}
                    </Button>
                  </CustomTooltip>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          {renderMenuItems(menuData)}
        </>
      )}
    </MenuWrapper>
  );
}

export default SidebarMenu;
