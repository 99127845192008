import { Button, Grid } from '@mui/material';

const CustomButton = ({ onClick, title, sx = {}, type, disabled = false }) => {
  const defaultStyles = {
    backgroundColor: disabled
      ? 'rgba(56, 5, 99, 0.2)'
      : type !== 'cancel'
      ? '#380563'
      : 'rgba(56, 5, 99, 0.06)',
    color: disabled ? '#b3b3b3' : type !== 'cancel' ? '#ffffff' : '#380563',
    minWidth: '119px',
    height: '40px',
    padding: '10px 35px',
    borderRadius: '8px',
    border: '1px solid transparent',
    opacity: disabled ? 0.5 : 1,
    cursor: disabled ? 'not-allowed' : 'pointer',
    '&:hover': {
      backgroundColor: disabled
        ? 'rgba(56, 5, 99, 0.2)'
        : type !== 'cancel'
        ? '#380563'
        : 'rgba(56, 5, 99, 0.1)',
      opacity: disabled ? 0.5 : 0.9
    },
    pointerEvents: disabled ? 'none' : 'auto'
  };

  return (
    <Grid item>
      <Button
        onClick={onClick}
        sx={{ ...defaultStyles, ...sx }}
        style={{ width: 'auto' }}
        disabled={disabled}
      >
        {title}
      </Button>
    </Grid>
  );
};

export default CustomButton;
