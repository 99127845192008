import React from 'react';
import {
  Typography,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Box
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';

interface PanelWithOutButtonProps {
  panelName?: string;
  onSearchChange?: (searchName: string) => void;
  renderCreateButton?: boolean;
  onBulkUploadClick?: () => void;
  renderBulkUploadButton?: boolean;
  data?: string;
}

const PanelWithOutButton: React.FC<PanelWithOutButtonProps> = ({
  panelName,
  onSearchChange = () => {},
  renderCreateButton = true,
  onBulkUploadClick = () => {},
  renderBulkUploadButton = false,
  data = ''
}) => {
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onSearchChange(value);
  };

  const searchColumn = panelName === 'General Category' ? 2.5 : 3;
  const createColumn = panelName === 'General Category' ? 2.5 : 3;
  const headerColumn = panelName === 'Roles' ? 9 : 6;
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent={'space-between'}
    >
      <Grid item xs={12} md={headerColumn}>
        <Typography
          sx={{
            fontSize: { xs: '16px', md: '18px' },
            fontWeight: 600,
            lineHeight: '22px',
            marginBottom: '2px',
            color: '#01011A'
          }}
        >
          {panelName}
        </Typography>
        <Typography
          sx={{
            fontSize: 12,
            color: '#39557E',
            fontWeight: 500,
            lineHeight: '14.5px',
            marginTop: 0
          }}
        >
          {data}
        </Typography>
      </Grid>

      <>
        <Grid item xs={12} md={searchColumn}>
          <TextField
            variant="outlined"
            placeholder="Search..."
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: {
                paddingRight: 0,
                height: { xs: '35px', md: '43px' },
                width: '100%'
              }
            }}
            fullWidth
            inputProps={{ maxLength: 320 }}
          />
        </Grid>
      </>
    </Grid>
  );
};

export default PanelWithOutButton;
