import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import SelectAllTransferList from './list';
import { usePanelName } from 'src/components/PageHeader/PanelNameProvider';
import BackButton from 'src/shared/backButton';

const panelName = 'Users';

const BatchForm: React.FC = () => {
  // const { setPanelName } = usePanelName();

  // useEffect(() => {
  //   setPanelName(panelName);
  // }, [setPanelName]);

  // const panelName = 'Organization';
  const headerName = 'Manage Teacher with Batches';
  const description = 'Create your batches and add your teachers to it and assign tests to these batches';
  // **********
  const { setPanelName, setDescription } = usePanelName();
  useEffect(() => {
    setPanelName(headerName);
    setDescription(description)
  }, [setPanelName]);

  return (
    <>
     <BackButton title="Back" />
      <SelectAllTransferList />
    </>
  );
};

export default BatchForm;
